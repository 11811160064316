import React from 'react';
import { useListContext } from 'react-admin';
import Loading from './Loading';

type WithLoadingProps = {
  children: React.ReactElement;
};

const WithLoading: React.FC<WithLoadingProps> = ({ children }) => {
  const { isLoading, isFetching } = useListContext();

  return isLoading || isFetching ? <Loading /> : children;
};

export default WithLoading;
