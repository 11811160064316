import { User } from './User/User.types';

export enum AdminAccessRightListEnum {
  'AllPermissions' = '*',
  'ManageQuests' = '/quests/*',
  'ReadQuests' = '/quests/:id|GET',
  'CreateQuests' = '/quests/:id|POST',
  'UpdateQuests' = '/quests/:id|PUT',
  'ManageAdmins' = '/admins/*',
  'ReadAdmins' = '/admins/:id|GET',
  'CreateAdmins' = '/admins/:id|POST',
  'UpdateAdmins' = '/admins/:id|PUT',
  'ManageAdminlogs' = '/adminlogs/*',
  'ReadAdminlogs' = '/adminlogs/:id|GET',
}

export enum AdminTypeEnum {
  SuperAdmin = '10',
  Admin = '20',
}

export enum AdminStatusEnum {
  Active = '1',
  Pasive = '0',
}

export type Admin = {
  id: number | string;
  email: string;
  accessRightList: AdminAccessRightListEnum[];
  type: AdminTypeEnum;
  status: AdminStatusEnum;
  createdAt: string | Date;
  createdBy: string;
  updatedAt: string | Date;
  updatedBy: string | null;
};

export const AdminAccessRightListLabelMap: { [key: string]: string } = {
  [AdminAccessRightListEnum.AllPermissions]: 'All Permissions',
  [AdminAccessRightListEnum.ManageQuests]: 'Manage Quests',
  [AdminAccessRightListEnum.ReadQuests]: 'Read Quests',
  [AdminAccessRightListEnum.CreateQuests]: 'Create Quests',
  [AdminAccessRightListEnum.UpdateQuests]: 'Update Quests',
  [AdminAccessRightListEnum.ManageAdmins]: 'Manage Admins',
  [AdminAccessRightListEnum.ReadAdmins]: 'Read Admins',
  [AdminAccessRightListEnum.CreateAdmins]: 'Create Admins',
  [AdminAccessRightListEnum.UpdateAdmins]: 'Update Admins',
  [AdminAccessRightListEnum.ManageAdminlogs]: 'Manage Adminlogs',
  [AdminAccessRightListEnum.ReadAdminlogs]: 'Read Adminlogs',
};

export const AdminTypeLabelMap: { [key: string]: string } = {
  [AdminTypeEnum.SuperAdmin]: 'Super Admin',
  [AdminTypeEnum.Admin]: 'Admin',
};

export const AdminStatusLabelMap: { [key: string]: string } = {
  [AdminStatusEnum.Active]: 'Active',
  [AdminStatusEnum.Pasive]: 'Passive',
};

export type Me = Admin & Pick<User, 'name' | 'photo'>;
