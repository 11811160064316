import React from 'react';
import { FunctionField, TextField } from 'react-admin';
import * as S from './EmptyField.styled';

type EmptyFieldProps = {
  source: string;
  label?: string;
  text?: React.ReactNode;
};

const EmptyField: React.FC<EmptyFieldProps> = ({
  source,
  label,
  text = '[Not Set]',
}) => {
  return (
    <S.EmptyFieldWrapper>
      <FunctionField
        render={() => (
          <TextField
            record={{ [source]: text }}
            source={source}
            label={label}
          />
        )}
      />
    </S.EmptyFieldWrapper>
  );
};

export default EmptyField;
