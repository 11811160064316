import React from 'react';
import { Edit, SaveButton, Toolbar, ToolbarProps } from 'react-admin';
import { QuestForm } from '../Shared/QuestForm';
import HeaderTitle from '../Shared/HeaderTitle/HeaderTitle';
import ResourceDetailTopToolbar from '../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { useMediaQuery } from '@mui/material';

const EditActions: React.FC<ToolbarProps> = (props) => (
  <Toolbar
    {...props}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 24px 0 24px !important',
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    }}
  >
    <SaveButton />
  </Toolbar>
);

const QuestEdit: React.FC = (props) => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Edit
      actions={<ResourceDetailTopToolbar resource="Quest" hasShowButton />}
      title={<HeaderTitle />}
      {...(isSmallDevice && {
        sx: {
          marginBottom: '2rem',
          paddingLeft: '1rem',
          '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none',
            height: 'auto',
          },
          '& .MuiCardContent-root': {
            padding: 0,
          },
        },
      })}
      {...props}
    >
      <QuestForm toolbar={<EditActions />} />
    </Edit>
  );
};

export default QuestEdit;
