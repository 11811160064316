import React from 'react';
import { Create } from 'react-admin';
import HeaderTitle from '../Shared/HeaderTitle/HeaderTitle';
import { CustomTopToolbarActions } from '../Shared/CustomTopToolbarActions';

import AdminForm from '../Shared/AdminForm/AdminForm';

const AdminCreate: React.FC = () => {
  return (
    <Create
      title={<HeaderTitle />}
      actions={<CustomTopToolbarActions title="Create Admin" />}
    >
      <AdminForm />
    </Create>
  );
};

export default AdminCreate;
