import React from 'react';
import {
  CreateButton,
  EditButton,
  Datagrid,
  Title,
  ListBase,
  TextField,
  TopToolbar,
  ExportButton,
  Pagination,
  SimpleListConfigurable,
  FunctionField,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Stack, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { EmptyFieldWithRecord } from '../Shared/EmptyFieldWithRecord';
import {
  AdminTypeLabelMap,
  AdminStatusLabelMap,
  AdminAccessRightListLabelMap,
} from '../Shared/Admin.types';
import {
  CustomArrayField,
  createTransformHandler,
} from '../Shared/CustomArrayField';
import { FilterForm } from '../Shared/FilterForm';
import { WithLoading } from '../Shared/Loading';
import { useResetList } from '../Shared/List.hooks';

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-input:focus ~ div .MuiButtonBase-root {
    color: #ff5722;
  }
`;

const filters = (fullWidth: boolean): React.ReactNode[] => {
  return [
    <StyledTextInput
      key="filterSearchInput"
      label="Search"
      source="q"
      alwaysOn
      fullWidth={fullWidth}
      resettable
    />,
  ];
};

const ListActions: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  return (
    <Stack
      direction={isSmallDevice ? 'column' : 'row'}
      marginTop={2}
      marginBottom={2}
    >
      <FilterForm filters={filters(isSmallDevice)} fullWidth={isSmallDevice} />
      <TopToolbar
        sx={{
          backgroundColor: 'transparent!important',
          width: '100%',
        }}
      >
        <CreateButton />
        <ExportButton />
      </TopToolbar>
    </Stack>
  );
};

const AdminList: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const notify = useNotify();
  const redirect = useRedirect();

  useResetList();

  const onError = (error: any): void => {
    notify(`Could not load list: ${error.message}`, { type: 'warning' });
    redirect('/dashboard');
  };

  return (
    <ListBase perPage={10} queryOptions={{ onError }}>
      <Title title="Galactic Entertainment" />
      <ListActions />
      <WithLoading>
        <>
          {isSmallDevice ? (
            <SimpleListConfigurable
              primaryText={(record) => record.email}
              secondaryText={(record) => AdminTypeLabelMap[record.type]}
              linkType="show"
            />
          ) : (
            <Datagrid bulkActionButtons={false}>
              <EmptyFieldWithRecord source="email" label="E-mail">
                <TextField source="email" />
              </EmptyFieldWithRecord>
              <CustomArrayField
                source="accessRightList"
                label="Access Rights"
                transform={createTransformHandler(AdminAccessRightListLabelMap)}
              />
              <EmptyFieldWithRecord source="type" label="Role">
                <FunctionField
                  render={(record: any) => {
                    return (
                      <TextField
                        source="type"
                        record={{
                          type: AdminTypeLabelMap[record?.type as string],
                        }}
                      />
                    );
                  }}
                />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="status">
                <FunctionField
                  render={(record: any) => {
                    return (
                      <TextField
                        source="status"
                        record={{
                          status: AdminStatusLabelMap[record?.status as string],
                        }}
                      />
                    );
                  }}
                />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="createdBy" label="Created By">
                <TextField source="createdBy" />
              </EmptyFieldWithRecord>
              <FunctionField render={() => <EditButton />} label="Actions" />
            </Datagrid>
          )}
          <Pagination />
        </>
      </WithLoading>
    </ListBase>
  );
};

export default AdminList;
