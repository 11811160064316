import * as React from 'react';
import { useNotify, useRedirect, Create } from 'react-admin';
import { QuestForm } from '../Shared/QuestForm';
import { CustomTopToolbarActions } from '../Shared/CustomTopToolbarActions';
import HeaderTitle from '../Shared/HeaderTitle/HeaderTitle';

type FormField = any;
type FormData = any;

const QuestCreate: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data: FormData): void => {
    notify(`Post created successfully`);
    redirect('edit', 'quests', data.id, data);
  };

  /**
   * Removes the fields with an empty string value from object.
   *
   * @param data Quest
   * @returns Quest
   */
  const transform = (data: FormData): FormData => {
    const sanitizedData: { [key: string]: FormField } = {};

    for (const key in data) {
      if (typeof data[key] === 'string' && data[key].length === 0) continue;

      sanitizedData[key] = data[key];
    }

    return sanitizedData;
  };

  return (
    <Create
      transform={transform}
      mutationOptions={{ onSuccess }}
      title={<HeaderTitle />}
      actions={<CustomTopToolbarActions title="Create Quest" hasBackButton />}
    >
      <QuestForm />
    </Create>
  );
};

export default QuestCreate;
