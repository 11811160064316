import React from 'react';

import { TextField, Show, SimpleShowLayout, DateField } from 'react-admin';
import { useMediaQuery, Divider } from '@mui/material';
import { HeaderTitle } from '../Shared/HeaderTitle';
import ResourceDetailTopToolbar from '../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { EmptyFieldWithRecord } from '../Shared/EmptyFieldWithRecord';

const AdminLogShow: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Show
      actions={<ResourceDetailTopToolbar resource="Admin Log" hasBackButton />}
      title={<HeaderTitle />}
      sx={{
        paddingBottom: isSmallDevice ? '1rem' : 'auto',
      }}
    >
      <SimpleShowLayout
        divider={<Divider flexItem />}
        sx={{
          padding: isSmallDevice ? '4px 8px' : '8px 16px',
        }}
      >
        <TextField source="id" />
        <EmptyFieldWithRecord source="email" label="E-mail">
          <TextField source="email" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="route">
          <TextField source="route" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="method">
          <TextField source="method" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="duration">
          <TextField source="duration" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="objectId" label="Object ID">
          <TextField source="objectId" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="params">
          <TextField source="params" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="createdAt" label="Created At">
          <DateField source="createdAt" />
        </EmptyFieldWithRecord>
      </SimpleShowLayout>
    </Show>
  );
};

export default AdminLogShow;
