import React from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { EditButton, ListButton, ShowButton } from 'react-admin';

type CustomTopToolbarActionsProps = {
  title: string;
  detailTitle?: string;
  children?: React.ReactNode;
  hasBackButton?: boolean;
  hasEditButton?: boolean;
  hasShowButton?: boolean;
};

const CustomTopToolbarActions: React.FC<CustomTopToolbarActionsProps> = ({
  children,
  title,
  detailTitle,
  hasBackButton = false,
  hasEditButton = false,
  hasShowButton = false,
}) => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      marginTop="18px"
      marginBottom="16px"
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontSize: isSmallDevice ? '1rem' : '1.25rem',
          color: '#ff5722',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {hasBackButton && (
          <ListButton
            icon={
              <ArrowBackIosNewIcon
                fontSize={isSmallDevice ? 'small' : 'medium'}
              />
            }
            label=""
            size="large"
            sx={{
              padding: '0 !important',
              minWidth: 'auto',
            }}
          />
        )}
        {title}
        {detailTitle && (
          <div
            style={{
              color: 'gray',
              fontSize: '12px',
              paddingLeft: '10px',
              paddingTop: '5px',
            }}
          >
            {detailTitle}
          </div>
        )}
      </Typography>
      {hasEditButton && <EditButton />}
      {hasShowButton && <ShowButton />}
      {children}
    </Stack>
  );
};

export default CustomTopToolbarActions;
