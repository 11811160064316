import React, { useEffect } from 'react';
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  SelectInput,
  SelectArrayInput,
  useRecordContext,
  SimpleFormProps,
} from 'react-admin';
import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
  useTiptapEditor,
  ImageButtons,
  ColorButtons,
} from 'ra-input-rich-text';
import Remove from '@mui/icons-material/Remove';
import { ToggleButton } from '@mui/material';
import useProviderFields from './useProviderFields';

const MyRichTextInputToolbar: React.FC<any> = ({ size, ...props }) => {
  const editor = useTiptapEditor();

  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={size} />
      <ColorButtons size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <ImageButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
      <ToggleButton
        aria-label="Add an horizontal rule"
        title="Add an horizontal rule"
        value="left"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        selected={editor && editor.isActive('horizontalRule')}
        size={size}
      >
        <Remove fontSize="inherit" />
      </ToggleButton>
    </RichTextInputToolbar>
  );
};

const QuestForm: React.FC<Omit<SimpleFormProps, 'children'>> = (props) => {
  const record = useRecordContext(props);
  const providerFields = useProviderFields();
  const { provider, providerContentType, providerActionType } = record || {};

  useEffect(() => {
    provider && providerFields.selectProvider(provider);
    providerContentType &&
      providerFields.selectProviderContentType(providerContentType);
    providerActionType &&
      providerFields.selectActionTypesOfProviderAndContentType(
        providerActionType
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    providerFields.selectProvider,
    providerFields.selectProviderContentType,
    providerFields.selectActionTypesOfProviderAndContentType,
    record,
  ]);

  const handleProviderSelectInputChange = ({
    target: { value },
  }: any): void => {
    providerFields.resetProvider();
    providerFields.selectProvider(value);
  };

  const handleProviderContentTypeSelectInputChange = ({
    target: { value },
  }: any): void => {
    providerFields.resetProviderContentType();
    providerFields.selectProviderContentType(value);
  };

  return (
    <SimpleForm {...props}>
      <TextInput source="name" validate={[required()]} fullWidth label="Name" />
      <TextInput
        source="description"
        validate={[required()]}
        label="Short Description"
        fullWidth
      />
      <NumberInput source="points" label="Points" />
      <BooleanInput source="isActive" label="Is Active?" />
      <SelectInput
        source="icon"
        label="Icon"
        choices={[
          { id: 'Retweet', name: 'Retweet' },
          { id: 'Follow Us', name: 'Follow Us' },
        ]}
        fullWidth
      />
      <NumberInput source="orderShow" label="Order Show" defaultValue={1} />
      <BooleanInput source="justOnce" label="Just Once?" />
      <TextInput source="url" label="URL" fullWidth />
      <RichTextInput
        source="details"
        toolbar={<MyRichTextInputToolbar size="large" />}
        label="Details"
      />
      <RichTextInput
        source="claimDetails"
        label="Claim Details"
        toolbar={<MyRichTextInputToolbar size="large" />}
      />
      <SelectInput
        source="provider"
        label="Provider"
        choices={providerFields.providers}
        fullWidth
        onChange={handleProviderSelectInputChange}
      />
      <SelectInput
        source="providerContentType"
        label="Provider Content Type"
        choices={providerFields.providerContentTypes || []}
        fullWidth
        onChange={handleProviderContentTypeSelectInputChange}
        disabled={!providerFields.selectedProvider}
      />
      <TextInput
        source="providerContent"
        label="Provider Content"
        fullWidth
        disabled={!providerFields.selectedProviderContentType}
      />
      <SelectArrayInput
        source="providerActionType"
        label="Action Type of Provider and Content Type"
        choices={providerFields.actionTypesOfProviderAndContentType || []}
        fullWidth
        disabled={!providerFields.selectedProviderContentType}
      />
      <TextInput source="actionLabel" label="Action Label" fullWidth />
      <TextInput source="claimLabel" label="Claim Label" fullWidth />
      <SelectInput
        source="verificationType"
        label="Verification Type"
        choices={[
          { id: 'manuel-text', name: 'Manuel Text' },
          { id: 'auto', name: 'Auto' },
        ]}
        fullWidth
      />
      <NumberInput source="verificationDelay" label="Verification Delay" />
      <NumberInput source="period" label="Period" />
      <NumberInput
        source="periodAllowedRepetition"
        label="Period Allowed Repetition"
        defaultValue={1}
      />
      <SelectArrayInput
        source="requiredInfo"
        label="Required Info"
        choices={[
          { id: 'email', name: 'Email' },
          { id: 'wallet', name: 'Wallet' },
        ]}
        fullWidth
      />
      <NumberInput source="repetitionLimit" label="Repetition Limit" />
      <NumberInput source="userLimit" label="User Limit" />
    </SimpleForm>
  );
};

export default QuestForm;
